import { Controller } from 'stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['link']

  clickTarget() {
    if (this.linkTarget.dataset.turbo === 'false') {
      return window.location.href = this.linkTarget.dataset.href
    }

    Turbo.visit(this.linkTarget.dataset.href)
  }
}
